<template>
  <div>
    <v-dialog v-model="productDialog" width="800" @input="closeEdit" persistent>
      <v-form
        ref="product_form"
        v-model="valid"
        lazy-validation
        class="pos-product-form"
      >
        <v-card-actions class="pos-cf-bottom-action">
          <v-row>
            <v-col md="12">
              <div class="pos-cf-action-heading">
                {{ product.id ? "Edit Product" : "Add Product" }}
              </div>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card style="border-radius:12px">
          <v-card-text class="pos-cf-body">
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="8" class="pt-0 pb-0">
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        outlined
                        background-color="#fff"
                        v-model="product.name"
                        hide-details="auto"
                        label="Product Name *"
                        placeholder="Product Name"
                        required
                        :rules="[(v) => !!v || 'Product name is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        hide-details="auto"
                        v-model="product.category_id"
                        :items="categories"
                        item-value="id"
                        item-text="name"
                        outlined
                        :menu-props="{ bottom: true, offsetY: true }"
                        background-color="#fff"
                        :label="`Categories*`"
                        required
                        :rules="[(v) => !!v || 'Category is required']"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        hide-details="auto"
                        label="Tax"
                        v-model="product.tax_type_id"
                        item-value="value"
                        item-text="text"
                        :items="taxTypes"
                        @change="taxChange"
                        :menu-props="{ bottom: true, offsetY: true }"
                        outlined
                        background-color="#fff"
                        required
                        :rules="[(v) => !!v || 'Tax type is required']"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        hide-details="auto"
                        label="Price (Pre Tax)*"
                        outlined
                        :prefix="currencyCode"
                        background-color="#fff"
                        v-model="product.pre_tax_price"
                        @change="calculateTaxVariation($event, 'pre')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        hide-details="auto"
                        label="Price (Post Tax)*"
                        outlined
                        :prefix="currencyCode"
                        background-color="#fff"
                        v-model="product.total_price"
                        @change="calculateTaxVariation($event, 'post')"
                        required
                        :rules="[(v) => !!v || 'Price is required']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="4" class="pt-0 pb-0">
                  <image-upload
                    @upload="
                      (data) => {
                        product.image = data;
                      }
                    "
                    @remove="
                      () => {
                        product.image = null;
                      }
                    "
                    :image_path="product.image_path"
                    :height="180"
                  ></image-upload>
                  <v-switch
                    class="mx-0 my-0"
                    v-model="product.enable_online_booking"
                    label="Enable Online Purchasing"
                  ></v-switch>
                  <v-switch
                    class="mx-0 my-0"
                    v-model="isEnableVariant"
                    label="Enable Variant"
                    @change="setFirstVariantPrice"
                  ></v-switch>
                </v-col>
              </v-row>
              <!-- <v-row v-if="isEnableDctErp">
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    background-color="#fff"
                    v-model="product.project_no"
                    hide-details="auto"
                    label="Project Number *"
                    placeholder="Project Number"
                    required
                    :rules="[(v) => !!v || 'Project number is required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    background-color="#fff"
                    v-model="product.task_name"
                    hide-details="auto"
                    label="Task Name *"
                    placeholder="Task Name"
                    required
                    :rules="[(v) => !!v || 'Task name is required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    background-color="#fff"
                    v-model="product.gl_code"
                    hide-details="auto"
                    label="Gl Code *"
                    placeholder="Gl Code"
                    required
                    :rules="[(v) => !!v || 'Gl Code is required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    background-color="#fff"
                    v-model="product.transaction_type"
                    hide-details="auto"
                    label="Transaction Type*"
                    placeholder="Transaction Type"
                    required
                    :rules="[(v) => !!v || 'Transaction Type is required']"
                  ></v-text-field>
                </v-col>
              </v-row> -->
              <v-row v-if="isEnableVariant" class="variant-fields">
                <v-col cols="12"><h3>Variant Details</h3></v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  v-for="(variant, index) in product_variants"
                  :key="index"
                >
                  <v-row style="position:relative">
                    <v-col cols="12" sm="12" md="4">
                      <v-select
                        hide-details="auto"
                        v-model="variant.attribute_name"
                        :items="attributes"
                        item-value="attribute_name"
                        item-text="attribute_name"
                        outlined
                        :menu-props="{ bottom: true, offsetY: true }"
                        background-color="#fff"
                        :label="`Attributes*`"
                        required
                        :rules="[(v) => !!v || 'Attribute is required']"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                      <v-text-field
                        hide-details="auto"
                        label="Price (Pre Tax)*"
                        outlined
                        :prefix="currencyCode"
                        background-color="#fff"
                        v-model="variant.pre_tax_price"
                        @change="
                          calculateVariantProductTaxVariation(
                            $event,
                            'pre',
                            index
                          )
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                      <v-text-field
                        hide-details="auto"
                        label="Price (Post Tax)*"
                        outlined
                        :prefix="currencyCode"
                        background-color="#fff"
                        v-model="variant.total_price"
                        @change="
                          calculateVariantProductTaxVariation(
                            $event,
                            'post',
                            index
                          )
                        "
                        required
                        :rules="[(v) => !!v || 'Price is required']"
                      ></v-text-field>
                    </v-col>
                    <div>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            color="red"
                            fab
                            x-small
                            absolute
                            top
                            right
                            dark
                            style="top: -9px;right: 0px;"
                            @click="removeProductVariant(index)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        Delete
                      </v-tooltip>
                    </div>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <div
                    v-if="isEnableVariant && this.product_variants.length < 5"
                    class="add_btn pb-4"
                    style="margin-top: -30px"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          color="teal"
                          fab
                          x-small
                          dark
                          @click="addProductVariant"
                        >
                          <v-icon small>mdi-plus-circle</v-icon>
                        </v-btn>
                      </template>
                      Add
                    </v-tooltip>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pos-cf-bottom-action">
            <v-btn color="darken-1" class="ma-4 text" text @click="closeEdit"
              >Close</v-btn
            >
            <v-spacer></v-spacer>

            <v-btn
              v-if="checkWritePermission($modules.outlet.productsOrder.slug)"
              color=" darken-1"
              class="ma-4 white--text blue-color"
              @click="addProduct"
              text
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </div>
</template>

<script>
export default {
  props: {
    productDialog: { type: Boolean, default: false },
    id: { type: Number, default: null },
    categories: { type: Array, default: () => [] },
    venueServiceId: { type: Number, default: null },
    outletId: { type: Number, default: null  }
  },
  data() {
    return {
      valid: true,
      refreshCountry: false,
      product: {
        enable_online_booking: false,
      },
      isEnableVariant: false,
      product_variants: [
        {
          id: "",
          name: "",
          pre_tax_price: 0,
          price: 0,
          total_price: 0,
        },
      ],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      deleted_product_variants: [],
      attributes: [],
    };
  },
  mounted() {
    // this.outlet_id = parseInt(atob(this.$route.params.data));
    // this.getCategoryList();
  },
  watch: {
    id: {
      immediate: true,
      handler(val) {
        if (val) {
          this.product = {};
          this.getProduct(val);
        }
      },
    },
    productDialog: {
      immediate: true,
      handler(val) {
        if (val) {
          this.product.image_path = "";
          if (val) {
            this.getAttributeList();
          }
        }
      },
    },
  },
  computed: {
    isEnableDctErp() {
      if (
        this.$store.getters.userVenues &&
        this.$store.getters.userVenues.length > 0
      ) {
        return this.$store.getters.userVenues[0].enable_dct_erp;
      }
      return 0;
    },
    taxTypes() {
      return this.$store.getters.getTaxTypes.data;
    },
  },

  methods: {
    addProduct() {
      if (!this.$refs.product_form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader();
      var formData = new FormData();
      for (let key in this.product) {
        if (this.product[key] != null && key != "id") {
          formData.append(key, this.product[key]);
        }
      }
      formData.append(`outlet_id`, this.outletId);
      let validate = true;
      if (this.isEnableVariant) {
        const seenIds = new Set(); // Create a set to store seen IDs
        this.product_variants.forEach((p, index) => {
          if (p.attribute_name) {
            if (seenIds.has(p.attribute_name)) {
              validate = false;
              this.hideLoader();
              this.showError(`Duplicate attribute found: ${p.attribute_name}`);
              // console.error(`Duplicate attribute found: ${p.name}`);
              return; // Skip this iteration
            }
            // If it's a new ID, add it to the set
            seenIds.add(p.attribute_name);
          }
          if (p.id) {
            formData.append(`product_variants[${index}][id]`, p.id);
          }
          if (this.product.id) {
            formData.append(
              `product_variants[${index}][parent_id]`,
              this.product.id
            );
          }

          formData.append(
            `product_variants[${index}][attribute_name]`,
            p.attribute_name
          );
          formData.append(
            `product_variants[${index}][pre_tax_price]`,
            p.pre_tax_price
          );
          formData.append(`product_variants[${index}][price]`, p.price);
          formData.append(
            `product_variants[${index}][total_price]`,
            p.total_price
          );
          formData.append(
            `product_variants[${index}][category_id]`,
            this.product.category_id
          );
          formData.append(
            `product_variants[${index}][tax_type_id]`,
            this.product.tax_type_id
          );
        });
        formData.append("isEnableVariant", true);
      }
      formData.set(
        "enable_online_booking",
        this.product.enable_online_booking ? 1 : 0
      );
      if (
        this.deleted_product_variants &&
        this.deleted_product_variants.length > 0
      ) {
        formData.append(
          `deleted_product_variants`,
          this.deleted_product_variants
        );
      }
      if (validate) {
        this.$http({
          method: "post",
          data: formData,
          url: `venues/pos/management/products${
            this.product.id ? "/" + this.product.id : ""
          }`,
          headers: {
            "Content-Type": "multipart/form-data; boundary=${form._boundary}",
          },
        })
          .then((response) => {
            if (response.status == 200) {
              this.showSuccess(
                "Product successfully " +
                  (this.product.id ? "updated" : "saved.")
              );
              this.product = {};
              this.product_variants = [];
              this.deleted_product_variants = [];
              this.isEnableVariant = false;
              this.$refs.product_form.resetValidation();
              this.hideLoader();
              this.$forceUpdate();
              this.$emit("added");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
            this.hideLoader();
          });
      }
    },
    getProduct() {
      this.showLoader("Loading");
      // let url = `venues/pos?category=${this.selectedCategory}`;
      if (this.id) {
        let url = `venues/pos/management/products/` + this.id;
        this.$http
          .get(url)
          .then((response) => {
            if (response.status == 200) {
              const data = response.data.data;
              if (data) {
                this.product = data;
                this.product.enable_online_booking = !!data.enable_online_booking;
                this.product.pre_tax_price = data.price.toFixed(2);
                this.product.image_path = data.image;
                if (data.variants && data.variants.length > 0) {
                  this.isEnableVariant = true;
                  this.product_variants = data.variants;
                  this.product_variants.map((item) => {
                    item.pre_tax_price = item.price;
                  });
                }
                this.$forceUpdate();
              } else {
                this.product = {};
              }
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
      }
    },
    closeEdit() {
      this.product = {};
      this.product_variants = [];
      this.isEnableVariant = false;
      this.deleted_product_variants = [];
      this.$refs.product_form.resetValidation();
      this.$emit("close");
    },
    taxChange() {
      if (this.product.price) {
        this.calculateTaxVariation(this.product.price, "pre");
      } else if (this.product.total_price) {
        this.calculateTaxVariation(this.product.total_price, "post");
      }
    },
    calculateTaxVariation(amount, type) {
      let taxTypeId = this.product.tax_type_id;
      let taxPercentage = 0;
      if (taxTypeId) {
        taxPercentage = this.taxTypes.find((item) => item.value == taxTypeId)
          .percentage;
      }
      const priceData = this.getTaxVariation(type, taxPercentage, amount);
      if (priceData) {
        this.product.price = priceData.price;
        this.product.pre_tax_price = priceData.price.toFixed(2);
        this.product.total_price = priceData.total_price;
      }
      this.$forceUpdate();
    },
    calculateVariantProductTaxVariation(amount, type, index) {
      let taxTypeId = this.product.tax_type_id;
      let taxPercentage = 0;
      if (taxTypeId) {
        taxPercentage = this.taxTypes.find((item) => item.value == taxTypeId)
          .percentage;
      }
      const priceData = this.getTaxVariation(type, taxPercentage, amount);
      if (priceData) {
        this.product_variants[index].price = priceData.price;
        this.product_variants[index].pre_tax_price = priceData.price.toFixed(2);
        this.product_variants[index].total_price = priceData.total_price;
      }
      this.$forceUpdate();
    },
    addProductVariant() {
      this.product_variants.push({
        id: "",
        name: "",
        price: this.product.price,
        pre_tax_price: this.product.pre_tax_price,
        total_price: this.product.total_price,
      });
    },
    setFirstVariantPrice() {
      if (
        !this.product.id &&
        this.product.pre_tax_price &&
        this.product.total_price &&
        this.product_variants[0] &&
        this.product_variants[0].total_price == 0
      ) {
        this.product_variants[0].price = this.product.price;
        this.product_variants[0].pre_tax_price = this.product.pre_tax_price;
        this.product_variants[0].total_price = this.product.total_price;
      }
    },
    removeProductVariant(index) {
      if (this.product_variants[index]) {
        if (this.product_variants[index].id) {
          this.confirmModel.id = index;
          this.confirmModel.type = "delete";
        } else {
          this.product_variants.splice(index, 1);
        }
      }
    },
    confirmActions(data) {
      if (data.type == "delete") {
        let id = this.product_variants[data.id].id;
        this.deleted_product_variants.push(id);
        this.product_variants.splice(data.id, 1);
      }
      this.confirmModel.id = null;
    },
    getAttributeList() {
      if (this.venueServiceId) {
        this.showLoader("Loading");
        // let url = `venues/pos?category=${this.selectedCategory}`;
        let url = `venues/outlets/product-management/attributes?outlet_id=${this.outletId}&venue_service_id=${this.venueServiceId}&page=1&per_page=100`;
        url += `${
          this.productNameSearch ? "&name=" + this.productNameSearch : ""
        }`;

        this.$http
          .get(url)
          .then((response) => {
            if (response.status == 200) {
              const data = response.data.data;
              if (data && data.length) {
                this.attributes = data;
              } else {
                this.attributes = [];
              }
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
      }
    },
  },
};
</script>

<style scoped>
.pos-cf-action-heading {
  color: var(--dark-blue, #112a46);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 18px;
}

form.v-form.pos-product-form {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
}

button.ma-2.text.v-btn.v-btn--text.theme--light.v-size--default.darken-1--text.pos-cf-skip-btn {
  color: #a7a7a7;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

button.ma-2.white--text.blue-color.v-btn.v-btn--text.theme--light.v-size--default.darken-1--text.pos-cf-btn-next {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  width: 75px;
  height: 40px;
}
.variant-fields {
  border: 1px solid #ccc;
  position: relative;
  /* max-height: 280px; */
  /* overflow-y: auto; */
}
/deep/ .v-dialog {
  overflow-x: hidden;
}
</style>
