<template>
  <v-container fluid>

    <v-row>
      <v-col md="4">
        <v-btn @click="backToOutlets" class="back-button text-capitalize" elevation="0" tile>
          <SvgIcon text="Products Management">
            <template v-slot:icon>
              <BackIcon/>
            </template>
          </SvgIcon>
        </v-btn>

      </v-col>
    </v-row>
    <div class="row row--dense mt-4">


      <div class="col-12 text-center">
        <div class="tab_container-blue  d-flex gap-x-8" style="border-bottom: 1px solid rgba(128, 130, 132, 0.25)">
          <transition name="bounce">
            <router-link :to="to" @click.native="goToProducts">
              <v-btn
                  x-large
                  style="padding: 2px"
                  tile
                  :class="[pmClass ? 'active_container_tab svg-stroke-icon' : '']"
                  plain
              >
                <DashboardIcon class=""/>
                <span class="ml-1 ">Products</span>
              </v-btn>
            </router-link>
          </transition>
          <router-link :to="to" @click.native="goToAttributes">
            <v-btn
                x-large
                text
                plain
                style="padding: 2px"
                tile
                :class="[amClass ? 'active_container_tab svg-stroke-icon' : '']"
            >
              <SchedulesIcon/> <span class="ml-1 ">Attributes</span>
            </v-btn>
          </router-link>

          <router-link :to="to" @click.native="goToCategories" >
            <v-btn
                x-large
                text
                plain
                style="padding: 2px"
                tile
                :class="[cmClass ? 'active_container_tab svg-stroke-icon' : '']"
            >
              <AnalyticsIcon/> <span class="ml-1 ">Categories</span>
            </v-btn>
          </router-link>
        </div>
      </div>
    </div>

    <v-row no-gutters class="pos product-management">

      <v-col md="12" class="mt-5">
        <v-card class="pa-4 shadow rounded-5">
          <v-row>
            <v-col md="3" sm="6">
              <div class="product-search">
                <v-text-field
                    v-model="productNameSearch"
                    density="compact"
                    variant="solo"
                    :label="
                    pmClass
                      ? 'Search Product'
                      : cmClass
                      ? 'Search Category'
                      : 'Search Attribute'
                  "
                    append-inner-icon="mdi-magnify"
                    single-line
                    hide-details
                    @keyup.enter="searchData"
                    @click:append="searchData"
                    clearable
                    @click:clear="clearSearch"
                    class="q-text-field shadow-0"
                    dense
                    outlined
                ></v-text-field>
              </div>
            </v-col>
            <v-col md="9" sm="6" class="pb-0">
              <div class="right-div" style="text-align: right;">
                <v-btn
                    class="btn-add-product shadow-0"
                    @click="addData"
                    :height="40"
                    v-if="
                    checkWritePermission($modules.outlet.productsOrder.slug)
                  "
                >+
                  {{
                    pmClass
                        ? "Add Product"
                        : cmClass
                            ? "Add Category"
                            : "Add Attribute"
                  }}</v-btn
                >
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" class="">
              <table
                  class=" mb-5 table border-collapse text-center"
                  id="pm-table"
                  v-if="pmClass"
              >
                <tr class="opacity-70 tr-neon tr-rounded text-center">
                  <th class="text-center">#</th>
                  <th class="text-center">Product Name</th>
                  <th class="text-center">Category</th>
                  <th class="text-center">Price</th>
                  <th class="text-center">Tax</th>
                  <th class="text-center">Total Price</th>
                  <th class="text-center">Action</th>
                </tr>
                <tr v-for="(product, index) in products" :key="index">
                  <td width="100px">
                    <div
                        class="md-table-cell-container md-ripple md-disabled d-flex justify-center align-center"
                        style="z-index: 0;"
                    >
                      <img
                          :src="getImage(product.image)"
                          aspect-ratio="1"
                          width="40"
                      />
                    </div>
                  </td>
                  <td>
                    <div>
                      {{ product.name }}
                      <div
                          class="p-variant"
                          v-if="product.variants && product.variants.length > 0"
                      >
                        <v-icon>mdi-package-variant</v-icon>
                        <v-chip
                            v-for="(variant, index) in product.variants"
                            :key="index"
                            class="ma-2"
                            small
                        >{{ variant.attribute_name }} :{{
                            variant.total_price | toCurrency
                          }}</v-chip
                        >
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      {{ product.category }}
                    </div>
                  </td>
                  <td>
                    {{ product.price | toCurrency }}
                  </td>
                  <td>
                    {{ product.tax_amount | toCurrency }}
                  </td>
                  <td>
                    {{ product.total_price | toCurrency }}
                  </td>
                  <td>
                    <v-icon
                        class="dark-blue-color ma-1"
                        @click="editProduct(product.id)"
                        title="Edit Product"
                        v-if="
                        checkWritePermission($modules.outlet.productsOrder.slug)
                      "
                    >mdi-pencil-outline</v-icon
                    >
                    <v-icon
                        class="dark-blue-color ma-1"
                        @click="deleteData(product.id, 'product')"
                        title="Delete Product"
                        v-if="
                        checkReadPermission($modules.outlet.productsOrder.slug)
                      "
                    >mdi-delete-outline</v-icon
                    >
                  </td>
                </tr>

                <tr v-if="products && !products.length">
                  <th colspan="7">Products Not Found</th>
                </tr>
              </table>

              <table
                  class="table border-collapse text-center mb-5"
                  id="pm-table"
                  v-if="cmClass"
              >
                <tr class="opacity-70 tr-neon tr-rounded text-center">
                  <th class="text-center">Category Name</th>
                  <th class="text-center">Product</th>
                  <th class="text-center">Action</th>
                </tr>
                <tr v-for="(category, index) in categories" :key="index">
                  <td>
                    <div>
                      {{ category.name }}
                    </div>
                  </td>
                  <td>
                    <div>
                      {{
                        category.products_count ? category.products_count : 0
                      }}
                    </div>
                  </td>
                  <td>
                    <v-icon
                        class="dark-blue-color ma-1"
                        @click="editCategory(category.id)"
                        title="Edit Category"
                        v-if="
                        checkWritePermission($modules.outlet.productsOrder.slug)
                      "
                    >mdi-pencil-outline</v-icon
                    >
                    <v-icon
                        class="dark-blue-color ma-1"
                        @click="deleteData(category.id, 'category')"
                        title="Delete Category"
                        v-if="
                        checkDeletePermission(
                          $modules.outlet.productsOrder.slug
                        )
                      "
                    >mdi-delete-outline</v-icon
                    >
                  </td>
                </tr>

                <tr v-if="categories && !categories.length">
                  <th colspan="5">category Not Found</th>
                </tr>
              </table>

              <table
                  class=" mb-5 table border-collapse text-center"
                  id="pm-table"
                  v-if="amClass"
              >
                <tr class="opacity-70 tr-neon tr-rounded text-center">
                  <th class="text-center">Attribute Name</th>
                  <th class="text-center">Action</th>
                </tr>
                <tr v-for="(attr, index) in attributes" :key="index">
                  <td>
                    <div>
                      {{ attr.attribute_name }}
                    </div>
                  </td>
                  <td>
                    <v-icon
                        class="dark-blue-color ma-1"
                        @click="editAttribute(attr.id)"
                        title="Edit Attribute"
                        v-if="checkWritePermission($modules.pos.management.slug)"
                    >mdi-pencil-outline</v-icon
                    >
                    <v-icon
                        class="dark-blue-color ma-1"
                        @click="deleteData(attr.id, 'attribute')"
                        title="Delete Attribute"
                        v-if="checkDeletePermission($modules.pos.management.slug)"
                    >mdi-delete-outline</v-icon
                    >
                  </td>
                </tr>

                <tr v-if="attributes && !attributes.length">
                  <th colspan="2">Attribute Not Found</th>
                </tr>
              </table>

              <v-pagination
                  v-if="pmClass && totalPages > 0"
                  v-model="page"
                  :length="totalPages"
                  class="new-pagination"
              ></v-pagination>
              <v-pagination
                  v-if="amClass && aTotalPages > 0"
                  v-model="aPage"
                  :length="aTotalPages"
                  class="new-pagination"

              ></v-pagination>
              <v-pagination
                  v-if="cmClass && cTotalPages > 0"
                  v-model="cPage"
                  :length="cTotalPages"
                  class="new-pagination"

              ></v-pagination>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <PosProductForm
        :productDialog="productDialog"
        :id="currentProductId"
        :venueServiceId="venueServiceId"
        :categories="categories"
        :outletId="id"
        @close="
        productDialog = false;
        currentProductId = null;
      "
        @added="
        productDialog = false;
        currentProductId = null;
        getProductList();
      "
    />
    <PosCategoryForm
        :categoryDialog="categoryDialog"
        :venueServiceId="venueServiceId"
        @close="
        categoryDialog = false;
        currentCategoryId = null;
      "
        @added="
        categoryDialog = false;
        getCategoryList();
      "
        :id="currentCategoryId"
    />
    <PosAttributeForm
        :attributeDialog="attributeDialog"
        :venueServiceId="venueServiceId"
        :outletId="id"
        @close="
        attributeDialog = false;
        currentAttributeId = null;
      "
        @added="
        attributeDialog = false;
        getAttributeList();
      "
        :id="currentAttributeId"
    />
    <confirm-model
        v-bind="confirmModel"
        @confirm="confirmActions"
        @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>

<script>
import PosProductForm from "@/views/Outlets/OutletProductForm.vue";
import PosCategoryForm from "@/views/Outlets/OutletCategoryForm.vue";
import PosAttributeForm from "@/views/Outlets/OutletAttributeForm.vue";
import images from "@/utils/images";
import DashboardIcon from "@/assets/images/misc/dashboard-icon.svg";
import AnalyticsIcon from "@/assets/images/misc/analytics-up.svg";
import SchedulesIcon from "@/assets/images/misc/calendar-icon.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import BackIcon from "@/assets/images/misc/back-icon.svg";
export default {
  components: {
    BackIcon, SvgIcon,
    SchedulesIcon, AnalyticsIcon, DashboardIcon,
    PosProductForm,
    PosCategoryForm,
    PosAttributeForm,
  },
  data() {
    return {
      to: "",
      id: null,
      image_path: "/../../src/assets/images/pos-img/1.png",
      cartItems: [],
      categories: [],
      products: [],
      attributes: [],
      right: null,
      productNameSearch: "",
      selectedCategory: "all",
      selectedTab: null,
      customerDialog: false,
      pmClass: true,
      cmClass: false,
      amClass: false,
      productDialog: false,
      categoryDialog: false,
      attributeDialog: false,
      page: 1,
      perPage: null,
      totalPages: 1,
      currentCategoryId: null,
      currentProductId: null,
      currentAttributeId: null,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      cTotalPages: 1,
      cPage: 1,
      aTotalPages: 1,
      aPage: 1,
    };
  },
  mounted() {
    this.id = parseInt(atob(this.$route.params.data));
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices").then(() => {
        this.getCategoryList();
        this.getAttributeList();
      });
    }
    if (this.$store.getters.getTaxTypes.status == false) {
      this.$store.dispatch("loadTaxTypes");
    }
    if (this.$router.currentRoute.name == "CategoryManagement") {
      this.cmClass = true;
      this.pmClass = false;
      this.getCategoryList();
    } else if (this.$router.currentRoute.name == "ProductManagement") {
      this.cmClass = false;
      this.pmClass = true;
      this.getCategoryList();
      this.getProductList();
    } else if (this.$router.currentRoute.name == "AttributeManagement") {
      this.cmClass = false;
      this.pmClass = false;
      this.amClass = true;
      this.getAttributeList(10);
    } else {
      this.cmClass = false;
      this.pmClass = true;
      this.getCategoryList();
      this.getProductList();
    }
  },
  watch: {
    page() {
      this.searchData();
    },
    cPage() {
      this.searchData();
    },
    aPage() {
      this.searchData();
    },
    $route(to) {
      if (to.name == "OutletsCategoryManagement") {
        this.productNameSearch = "";
        this.cmClass = true;
        this.pmClass = false;
        this.amClass = false;
        this.getCategoryList();
      } else if (to.name == "OutletsProductManagement") {
        this.productNameSearch = "";
        this.cmClass = false;
        this.pmClass = true;
        this.amClass = false;
        this.getProductList();
      } else if (to.name == "OutletsAttributeManagement") {
        this.productNameSearch = "";
        this.cmClass = false;
        this.pmClass = false;
        this.amClass = true;
        this.getAttributeList(10);
      } else {
        this.pmClass = true;
        this.cmClass = false;
        this.amClass = false;
        this.getProductList();
      }
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Product" : "Edit Product";
    },
    venueServiceId() {
      const filteredService = this.$store.getters.getVenueServices.data.find(
          (item) => item.name.toLowerCase() === "pos"
      );
      if (filteredService) {
        return filteredService.id;
      } else {
        return null;
      }
    },
  },
  methods: {
    goToProducts() {
      this.$router.push({
        name: "OutletsProductManagement",
        params: { data: btoa(this.id) },
      });
    },
    goToCategories() {
      this.$router.push({
        name: "OutletsCategoryManagement",
        params: { data: btoa(this.id) },
      });
    },
    goToAttributes(){
      this.$router.push({
        name: "OutletsAttributeManagement",
        params: { data: btoa(this.id) },
      });
    },
    backToOutlets() {
      this.$router.push({
        name: "OutletView",
        params: { data: btoa(this.id) },
      });
    },
    clearSearch() {
      setTimeout(() => {
        this.getProductList(this.currentYear, true);
      }, 100);
    },
    getProductList() {
      this.showLoader("Loading");
      let url = `venues/outlets/product-management/products?outlet_id=${this.id}&page=`;
      url += this.page + "&per_page=" + (this.perPage != null ? this.perPage : 10);
      url += `${
          this.productNameSearch ? "&product_name=" + this.productNameSearch : ""
      }`;

      this.$http
          .get(url)
          .then((response) => {
            if (response.status == 200) {
              const data = response.data.data;
              if (data && data.length) {
                this.totalPages = response.data.total_pages;
                this.products = data;
              } else {
                this.products = [];
                this.totalPages = 1;
              }
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },

    getCategoryList() {
      if (this.venueServiceId) {
        this.showLoader("Loading");

        let url = `venues/outlets/product-management/category?outlet_id=${this.id}&venue_service_id=${this.venueServiceId}&page=`;
        url +=
            this.cPage +
            "&per_page=" +
            (this.perPage != null ? this.perPage : 10);
        url += `${
            this.productNameSearch ? "&name=" + this.productNameSearch : ""
        }`;

        this.$http
            .get(url)
            .then((response) => {
              if (response.status == 200) {
                const data = response.data.data;
                if (data && data.length) {
                  this.cTotalPages = response.data.total_pages;
                  this.categories = data;
                } else {
                  this.categories = [];
                  this.cTotalPages = 1;
                }
                this.hideLoader();
              }
            })
            .catch((error) => {
              this.hideLoader();
              this.errorChecker(error);
            });
      }
    },

    getAttributeList(per_page = 10) {
      if (this.venueServiceId) {
        this.showLoader("Loading");
        // let url = `venues/pos?category=${this.selectedCategory}`;
        let url = `venues/outlets/product-management/attributes?outlet_id=${this.id}&venue_service_id=${this.venueServiceId}&page=`;
        url += this.aPage + "&per_page=" + (per_page != null ? per_page : 10);
        url += `${
            this.productNameSearch ? "&name=" + this.productNameSearch : ""
        }`;

        this.$http
            .get(url)
            .then((response) => {
              if (response.status == 200) {
                const data = response.data.data;
                if (data && data.length) {
                  this.aTotalPages = response.data.total_pages;
                  this.attributes = data;
                } else {
                  this.attributes = [];
                  this.aTotalPages = 1;
                }
                this.hideLoader();
              }
            })
            .catch((error) => {
              this.hideLoader();
              this.errorChecker(error);
            });
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    addData() {
      if (this.pmClass) {
        this.productDialog = true;
        this.categoryDialog = false;
        this.attributeDialog = false;
      } else if (this.cmClass) {
        this.categoryDialog = true;
        this.attributeDialog = false;
        this.productDialog = false;
      } else if (this.amClass) {
        this.attributeDialog = true;
        this.categoryDialog = false;
        this.productDialog = false;
      } else {
        this.productDialog = false;
        this.categoryDialog = false;
        this.attributeDialog = false;
      }
    },
    searchData() {
      if (this.pmClass) {
        this.getProductList();
      } else if (this.cmClass) {
        this.getCategoryList();
      } else if (this.amClass) {
        this.getAttributeList(10);
      }
    },
    editCategory(id) {
      this.currentCategoryId = id;
      this.categoryDialog = true;
    },
    editProduct(id) {
      this.currentProductId = id;
      this.productDialog = true;
    },
    editAttribute(id) {
      this.currentAttributeId = id;
      this.attributeDialog = true;
    },
    deleteData(id, type) {
      this.confirmModel = {
        id: id,
        title: `Do you want to delete this ${type}?`,
        description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: type,
      };
    },
    confirmActions(data) {
      let type = "";
      if (data.type == "product") {
        type = "products/" + data.id;
      } else if (data.type == "category") {
        type = "category/" + data.id;
      } else if (data.type == "attribute") {
        type = "attributes/" + data.id;
      } else {
        return false;
      }
      this.showLoader("Loading");
      let url = `venues/pos/management/${type}`;
      this.$http
          .delete(url)
          .then((response) => {
            if (response.status == 200) {
              const res = response.data;
              if (res) {
                if (data.type == "product") {
                  this.showSuccess("Product deleted successfully.");
                  this.getProductList();
                } else if (data.type == "category") {
                  this.showSuccess("Category deleted successfully.");
                  this.getCategoryList();
                } else if (data.type == "attribute") {
                  this.showSuccess("Attribute deleted successfully.");
                  this.getAttributeList();
                }
              }
            } else {
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    getImage(image, type = "product") {
      return image && image != "null"
          ? this.s3BucketURL + image
          : type == "product"
              ? images["default"]
              : images["empty_cart"];
    },
  },
};
</script>
<style scoped>
table.pm-table {
  width: 100%;
}
table.pm-table tr th,
table.pm-table tr td {
  text-align: center;
  border-bottom: 1px solid #000;
}

.pm-table {
  border-collapse: collapse;
  border-radius: 6px;
  border-style: hidden; /* hide standard table (collapsed) border */
  box-shadow: 0 0 0 1px #dbdbdb; /* this draws the table border  */
}
.pm-table th,
.pm-table td,
.reciept_details_total_table th,
.reciept_details_total_table td {
  border: transparent !important;
  color: #112a45;
  border-bottom: 1px solid #dbdbdb !important;
  padding: 10px 0px;
}

.pm-table tr th {
  text-align: center;
  padding: 10px 0px;
}
.pm-table tr:first-child th {
  background-color: #e9f1f6;
}
.pm-table .order-number {
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
}

table.pm-table.child {
  margin: 0 3%;
  width: 93%;
}
table.pm-table.child th {
  background-color: #112a45;
  color: #f7f7f5;
}

.dark-blue-color {
  color: #112a46;
}
.pm-title {
  color: var(--dark-blue, #112a46);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}
.product-management .btn-tab {
  background: #fff;
}
.product-management .btn-tab.active {
  color: #112a46;
}
.product-management .btn-add-product {
  color: var(--white, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  background: var(--dark-blue, #112a46);
}
.product-management .pm-card {
  box-shadow: 0 0 0 1px #dbdbdb;
  border-radius: 6px;
}
.product-management .v-card__title.cart-title {
  padding: 20px 35px !important;
}
.product-management .v-card__title.cart-title .text-h5 {
  padding: 10px 0px;
  color: var(--dark-blue, #112a46);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
</style>
