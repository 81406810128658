<template>
  <div>
    <v-dialog
      v-model="categoryDialog"
      width="480"
      @input="closeEdit"
      persistent
    >
      <v-form
        ref="category_form"
        autocomplete="off"
        v-model="valid"
        class="pos-product-form"
      >
        <v-card-actions class="pos-cf-bottom-action">
          <v-row>
            <v-col md="12">
              <div class="pos-cf-action-heading">
                {{ category.id ? "Edit Category" : "Add Category" }}
              </div>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card style="border-radius:12px">
          <v-card-text class="pos-cf-body">
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                  <v-text-field
                    outlined
                    background-color="#fff"
                    v-model="category.name"
                    hide-details="auto"
                    label="Category Name *"
                    placeholder="Category Name"
                    required
                    :rules="[(v) => !!v || 'Category is required']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pos-cf-bottom-action">
            <v-btn color="darken-1" class="ma-4 text" text @click="closeEdit"
              >Close</v-btn
            >
            <v-spacer></v-spacer>

            <v-btn
              v-if="checkWritePermission($modules.outlet.productsOrder.slug)"
              color=" darken-1"
              class="ma-4 white--text blue-color"
              @click="addCategory"
              text
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    categoryDialog: { type: Boolean, default: false },
    id: { type: Number, default: null },
    venueServiceId: { type: Number, default: null },
  },
  data() {
    return {
      outlet_id: null,
      valid: false,
      category: {},
    };
  },
  watch: {
    id: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getCategory(val);
        }
      },
    },
  },
  mounted() {
    this.outlet_id = parseInt(atob(this.$route.params.data));
  },
  methods: {
    addCategory() {
      if (!this.$refs.category_form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      if (!this.venueServiceId) {
        this.showError("Venue Service Required, Please refresh the page.");
        return false;
      }
      this.showLoader();
      var formData = new FormData();
      for (let key in this.category) {
        if (this.category[key] != null && key != "id") {
          formData.append(key, this.category[key]);
        }
      }

      formData.append(`outlet_id`, this.outlet_id);
      formData.append("venue_service_id", this.venueServiceId);

      this.$http({
        method: "post",
        data: formData,
        url: `venues/pos/management/category${
          this.category.id ? "/" + this.category.id : ""
        }`,
        headers: {
          "Content-Type": "multipart/form-data; boundary=${form._boundary}",
        },
      })
        .then((response) => {
          if (response.status == 200) {
            this.showSuccess(
              "Category successfully " + this.category.id ? "updated" : "saved."
            );
            this.category = {};
            this.hideLoader();
            this.$emit("added");
          }
        })
        .catch((error) => {
          this.errorChecker(error);
          this.hideLoader();
        });
    },
    getCategory() {
      this.showLoader("Loading");
      if (this.id) {
        let url = `venues/pos/management/category/` + this.id;
        this.$http
          .get(url)
          .then((response) => {
            if (response.status == 200) {
              const data = response.data.data;
              if (data) {
                this.category = data;
              } else {
                this.category = {};
              }
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
      }
    },
    closeEdit() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.pos-cf-action-heading {
  color: var(--dark-blue, #112a46);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 18px;
}

form.v-form.pos-product-form {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
}

button.ma-2.text.v-btn.v-btn--text.theme--light.v-size--default.darken-1--text.pos-cf-skip-btn {
  color: #a7a7a7;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

button.ma-2.white--text.blue-color.v-btn.v-btn--text.theme--light.v-size--default.darken-1--text.pos-cf-btn-next {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  width: 75px;
  height: 40px;
}
/deep/ .v-dialog {
  overflow-y: unset;
}
</style>
